import { createContext, useContext, useCallback } from "react";

import {
  getOfferingsAPICall,
  addOfferingAPICall,
  getApiProjectsAPICall,
  generateOfferingLinkAPICall,
  usageAPICall,
} from "../API/licensing/LicensingService";

export const LicensingContext = createContext({
  addOffering: () => {},
  getOfferings: () => {},
  generateOfferingLink: () => {},
});

const LicensingContextProvider = ({ children }) => {
  const addOffering = useCallback(
    async (data, callbackFunction, callbackError) => {
      try {
        addOfferingAPICall(
          data,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const getOfferings = useCallback(async (callbackFunction, callbackError) => {
    try {
      getOfferingsAPICall(
        function (response) {
          callbackFunction(response);
        },
        function (error) {
          callbackError(error);
        }
      );
    } catch (error) {
      callbackError(error);
    }
  });

  const getAPIProjects = useCallback(
    async (callbackFunction, callbackError) => {
      try {
        getApiProjectsAPICall(
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const generateOfferingLink = useCallback(
    async (projectID, offeringID, callbackFunction, callbackError) => {
      try {
        generateOfferingLinkAPICall(
          projectID,
          offeringID,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  const usageForProject = useCallback(
    async (projectID, startDate, endDate, callbackFunction, callbackError) => {
      try {
        usageAPICall(
          projectID,
          startDate,
          endDate,
          function (response) {
            callbackFunction(response);
          },
          function (error) {
            callbackError(error);
          }
        );
      } catch (error) {
        callbackError(error);
      }
    }
  );

  return (
    <LicensingContext.Provider
      value={{
        addOffering,
        getOfferings,
        generateOfferingLink,
        getAPIProjects,
        usageForProject,
      }}
    >
      {children}
    </LicensingContext.Provider>
  );
};

export const useLicensingContext = () => useContext(LicensingContext);

export default LicensingContextProvider;
