import { POST, GET } from "../API";

export const getOfferingsAPICall = (onSuccess, onError) => {
  GET(`/api/v1/administration/licensing/get_offerings`, onSuccess, onError);
};

export const addOfferingAPICall = (data, onSuccess, onError) => {
  POST(
    `/api/v1/administration/licensing/add_offering`,
    data,
    onSuccess,
    onError
  );
};

export const getApiProjectsAPICall = (onSuccess, onError) => {
  GET(`/api/v1/administration/licensing/api_projects`, onSuccess, onError);
};

export const generateOfferingLinkAPICall = (
  projectID,
  offerID,
  onSuccess,
  onError
) => {
  const data = { project_id: projectID, offer_id: offerID };

  POST(
    `/api/v1/administration/licensing/generate_offering_link_for_project`,
    data,
    onSuccess,
    onError
  );
};

export const usageAPICall = (
  projectID,
  startDate,
  endDate,
  onSuccess,
  onError
) => {
  const data = {
    project_id: projectID,
    start_date: startDate,
    end_date: endDate,
  };

  POST(`/api/v1/administration/licensing/usage`, data, onSuccess, onError);
};
