import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../../Assets/styles/colors";
import PrimaryButton from "../../Generic Components/PrimaryButton";
import { useEffect } from "react";

const AddOfferModal = ({ isOpen, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    name: "",
    interval: "month",
    price_per_user: "",
    ios: false,
    android: false,
    web: false,
    desktop: false,
    teams: false,
    slack: false,
    allowed_tokens: "",
    allowed_video_minutes: "",
    allowed_audio_minutes: "",
    api: false,
    api_price: "",
    api_tokens_included: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSave = () => {
    console.log("form data", formData);
    onSave(formData);
    onClose();
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  // Validation function for required fields
  const isFormValid = () => {
    var valid = false;

    valid =
      typeof formData["name"] === "string" && formData["name"].trim() !== "";

    valid = valid && isNumberValueValid(formData["price_per_user"]);
    valid = valid && isNumberValueValid(formData["allowed_tokens"]);
    valid = valid && isNumberValueValid(formData["allowed_video_minutes"]);
    valid = valid && isNumberValueValid(formData["allowed_audio_minutes"]);
    valid = valid && isNumberValueValid(formData["api_price"]);
    valid = valid && isNumberValueValid(formData["api_tokens_included"]);

    return valid;
  };

  const isNumberValueValid = (number) => {
    // Check if the number is an empty string
    if (typeof number === "string" && number.trim() === "") {
      return false;
    }

    // Try to parse the value if it's a string
    const parsedValue = typeof number === "string" ? Number(number) : number;

    const returnValue =
      typeof parsedValue === "number" &&
      Number.isInteger(parsedValue) &&
      parsedValue >= 0;
    return returnValue;
  };

  // Reset form data when modal is opened
  useEffect(() => {
    if (isOpen) {
      setFormData({
        name: "",
        interval: "month",
        price_per_user: "",
        ios: false,
        android: false,
        web: false,
        desktop: false,
        teams: false,
        slack: false,
        allowed_tokens: "",
        allowed_video_minutes: "",
        allowed_audio_minutes: "",
        api: false,
        api_price: "",
        api_tokens_included: "",
      });
    }
  }, [isOpen]); // D

  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalHeader>
          <h2>Add New Offer</h2>
          <CloseButton onClick={onClose}>×</CloseButton>
        </ModalHeader>
        <ModalContent>
          <Label>* Name:</Label>
          <Input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />

          <Label>* Interval:</Label>
          <Select
            name="interval"
            value={formData.interval}
            onChange={handleChange}
          >
            <option value="month">Month</option>
            <option value="year">Year</option>
          </Select>

          <Label>* Price/User (in CENTS):</Label>
          <Input
            type="number"
            name="price_per_user"
            value={formData.price_per_user}
            onChange={handleChange}
            onWheel={numberInputOnWheelPreventChange}
          />

          <Label>iOS:</Label>
          <Select name="ios" value={formData.ios} onChange={handleChange}>
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </Select>

          <Label>Android:</Label>
          <Select
            name="android"
            value={formData.android}
            onChange={handleChange}
          >
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </Select>

          <Label>Web:</Label>
          <Select name="web" value={formData.web} onChange={handleChange}>
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </Select>

          <Label>Desktop:</Label>
          <Select
            name="desktop"
            value={formData.desktop}
            onChange={handleChange}
          >
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </Select>

          <Label>Teams:</Label>
          <Select name="teams" value={formData.teams} onChange={handleChange}>
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </Select>

          <Label>Slack:</Label>
          <Select name="slack" value={formData.slack} onChange={handleChange}>
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </Select>

          <Label>* Allowed Tokens:</Label>
          <Input
            type="number"
            name="allowed_tokens"
            value={formData.allowed_tokens}
            onChange={handleChange}
            onWheel={numberInputOnWheelPreventChange}
          />

          <Label>* Allowed Video Minutes:</Label>
          <Input
            type="number"
            name="allowed_video_minutes"
            value={formData.allowed_video_minutes}
            onChange={handleChange}
            onWheel={numberInputOnWheelPreventChange}
          />

          <Label>* Allowed Audio Minutes:</Label>
          <Input
            type="number"
            name="allowed_audio_minutes"
            value={formData.allowed_audio_minutes}
            onChange={handleChange}
            onWheel={numberInputOnWheelPreventChange}
          />

          <Label>* API:</Label>
          <Select name="api" value={formData.api} onChange={handleChange}>
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </Select>

          <Label>* API Price (in CENTS):</Label>
          <Input
            type="number"
            name="api_price"
            value={formData.api_price}
            onChange={handleChange}
            onWheel={numberInputOnWheelPreventChange}
          />

          <Label>* API Tokens Included:</Label>
          <Input
            type="number"
            name="api_tokens_included"
            value={formData.api_tokens_included}
            onChange={handleChange}
            onWheel={numberInputOnWheelPreventChange}
          />

          <SaveButtonContainer>
            <PrimaryButton
              icon={null}
              text="Add new offer"
              onClick={handleSave}
              disabled={!isFormValid()} // Disable button if form is invalid
            />
          </SaveButtonContainer>
        </ModalContent>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default AddOfferModal;

const SaveButtonContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: ${colors.background};
  width: 500px;
  max-width: 90%;
  max-height: 70vh;
  padding: 20px;
  border-radius: 8px;
  overflow-y: auto;
  color: ${colors.white};
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: ${colors.white};
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Label = styled.label`
  font-size: 14px;
  color: ${colors.white};
`;

const Input = styled.input`
  padding: 8px;
  border: 1px solid ${colors.dashBoard.tableBorder};
  border-radius: 4px;
  background-color: ${colors.dashBoard.inputBg};
  color: ${colors.white}; /* Set text color */
  -moz-appearance: textfield; /* Firefox */
  -webkit-appearance: none; /* Chrome, Safari, Edge */
  appearance: none; /* Standard */

  /* Remove spinners for all modern browsers */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* Prevent extra margin */
  }
`;

const Select = styled.select`
  padding: 8px;
  border: 1px solid ${colors.dashBoard.tableBorder};
  border-radius: 4px;
  background-color: ${colors.dashBoard.inputBg};
  color: ${colors.white};
  cursor: pointer;
  padding-right: 30px; /* Add padding to make space for the arrow */
  appearance: none; /* Remove default dropdown styling */
  -webkit-appearance: none; /* For WebKit browsers */
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10"><path fill="%23ECECEC" d="M0 0l5 5 5-5z"/></svg>'); /* Custom down arrow */
  background-repeat: no-repeat;
  background-position: center right 10px; /* Align horizontally to the right */
  background-position-y: calc(50% + 5px); /* Center vertically and shift down */
  background-size: 12px; /* Adjust arrow size */
`;

const SaveButton = styled(PrimaryButton)`
  margin-top: 20px;
  height: 40px;
`;
