import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../Assets/styles/colors";
import { notifySuccess, notifyError } from "../../../Helpers/Notifications";
import PrimaryButton from "../../Generic Components/PrimaryButton";
import { useLicensingContext } from "../../../Context/LicensingContext";
import AddOfferModal from "./AddOfferModal";
import SendOfferModal from "./SendOfferModal";

const PlatformOfferings = ({ setIsLoading }) => {
  const [offers, setOffers] = useState([]);
  const [addOfferModal, setAddOfferModal] = useState(false);
  const [sendOfferModal, setSendOfferModal] = useState(false);
  const [selectedOfferID, setSelectedOfferID] = useState(null);
  const [projects, setProjects] = useState([]);

  const { getOfferings, addOffering, getAPIProjects } = useLicensingContext();

  const fetchOffers = () => {
    setIsLoading(true);
    getOfferings(
      (response) => {
        if (response.data.offerings) {
          setOffers(response.data.offerings);
        }
        setIsLoading(false);
      },
      (error) => {
        notifyError("Something went wrong");
        setIsLoading(false);
      }
    );
  };

  const addOffer = (data) => {
    setIsLoading(true);
    addOffering(
      data,
      (response) => {
        notifySuccess("Offer added");
        setIsLoading(false);
        fetchOffers();
      },
      (error) => {
        notifyError("Something went wrong");
        setIsLoading(false);
      }
    );
  };

  const handleSendOffer = (offerID) => {
    setSelectedOfferID(offerID);

    setIsLoading(true);
    getAPIProjects(
      (response) => {
        setProjects(response.data.projects);
        setIsLoading(false);
        setSendOfferModal(true);
      },
      (error) => {
        notifyError("Something went wrong");
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchOffers();
  }, []);

  return (
    <PlatformOfferingsContainer>
      <AddOfferModal
        isOpen={addOfferModal}
        onClose={() => {
          setAddOfferModal(false);
        }}
        onSave={(item) => {
          addOffer(item);
        }}
      />
      <SendOfferModal
        projects={projects}
        offerID={selectedOfferID}
        isOpen={sendOfferModal}
        setIsLoading={setIsLoading}
        onClose={() => {
          setSendOfferModal(false);
        }}
      />
      <ListWrapper>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Interval</th>
              <th>Price/User</th>
              <th>iOS</th>
              <th>Android</th>
              <th>Web</th>
              <th>Desktop</th>
              <th>Teams</th>
              <th>Slack</th>
              <th>Tokens</th>
              <th>Video</th>
              <th>Audio</th>
              <th>API</th>
              <th>Price</th>
              <th>Tokens</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {offers.map((offer, index) => (
              <tr key={index}>
                <td>{offer.name}</td>
                <td>{offer.interval}</td>
                <td>${(offer.price_per_user / 100).toFixed(2)}</td>
                <CompactCell>{offer.ios ? "Yes" : "No"}</CompactCell>
                <CompactCell>{offer.android ? "Yes" : "No"}</CompactCell>
                <CompactCell>{offer.web ? "Yes" : "No"}</CompactCell>
                <CompactCell>{offer.desktop ? "Yes" : "No"}</CompactCell>
                <CompactCell>{offer.teams ? "Yes" : "No"}</CompactCell>
                <CompactCell>{offer.slack ? "Yes" : "No"}</CompactCell>
                <td>{offer.allowed_tokens}</td>
                <td>{offer.allowed_video_minutes}</td>
                <td>{offer.allowed_audio_minutes}</td>
                <APIHighlight>{offer.api ? "Yes" : "No"}</APIHighlight>
                <APIHighlight>
                  {offer.api_price !== null
                    ? `$${(offer.api_price / 100).toFixed(2)}`
                    : ""}
                </APIHighlight>
                <APIHighlight>{offer.api_tokens_included ?? ""}</APIHighlight>
                <td>
                  <SendButton onClick={() => handleSendOffer(offer.id)}>
                    Send
                  </SendButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ListWrapper>
      <ButtonContainer>
        <PrimaryButton
          icon={null}
          text="+ Add new offer"
          onClick={() => setAddOfferModal(true)}
        />
      </ButtonContainer>
    </PlatformOfferingsContainer>
  );
};

export default PlatformOfferings;

const PlatformOfferingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px;
  background: ${colors.dashBoard.bg};
  height: 100vh;
  color: ${colors.white};
  overflow: hidden; // Prevent horizontal overflow
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.dashBoard.elementBg};
  border-radius: 10px;
  padding: 20px;
  max-height: 70vh; // Set a max height for the table wrapper
  overflow-y: auto; // Enable vertical scrolling
  overflow-x: hidden; // Disable horizontal scrolling
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;

  th,
  td {
    padding: 6px 10px; // Reduced padding for compactness
    border: 1px solid ${colors.dashBoard.tableBorder};
    color: ${colors.white};
  }

  th {
    background-color: ${colors.dashBoard.tableHeader};
    font-weight: bold;
  }

  td {
    background-color: ${colors.dashBoard.tableRow};
  }
`;

// Compact cell for boolean columns
const CompactCell = styled.td`
  width: 50px; // Set fixed width for boolean columns
  text-align: center;
`;

const APIHighlight = styled.td`
  background-color: ${colors.purple20}; // Use a color from your palette
  color: ${colors.white};
`;

const SendButton = styled.button`
  padding: 5px 10px;
  background-color: ${colors.purple};
  color: ${colors.white};
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.primaryHover};
  }
`;

const ButtonContainer = styled.div`
  align-self: flex-start;
  width: auto;
`;
